import { render, staticRenderFns } from "./PoiQuickAdd.vue?vue&type=template&id=4a267f08&scoped=true"
import script from "./PoiQuickAdd.vue?vue&type=script&lang=js"
export * from "./PoiQuickAdd.vue?vue&type=script&lang=js"
import style0 from "./PoiQuickAdd.vue?vue&type=style&index=0&id=4a267f08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a267f08",
  null
  
)

export default component.exports