<template>
    <v-row no-gutters style="contain: content; height:inherit">
      <!-- Map View -->
      <v-col cols="12" md="12" sm="12" style="height: inherit">
        <v-card class="mapLocal">
          <v-btn @click="$emit('close')" style="position: absolute; z-index: 10; right: 10px; top: 10px; left: auto" x-small fab color="primary"><v-icon>close</v-icon></v-btn>
          <!-- Map -->
          <gmap-map
            ref="mapRef"
            key="mapKey"
            id="map"
            :center="center"
            :zoom="zoom"
            class="gMap"
            map-type-id="terrain"
            style="width: inherit; height: 100%; z-index: 1"
            @click="addMarker"
            @zoom_changed="getZoom"
            @bounds_changed="getBounds"
            :options="{
                scrollwheel: true,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
            }"
          >
            <!-- Markers -->
            <gmap-marker
              v-if="place && place.geometry"
              :options="{
                draggable: true,
              }"
              :position="{
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              }"
              @dragend="updateCoordinates"
            />
            <gmap-marker
              v-for="(marker, index) in markersInBounds"
              :key="index"
              :position="{
                lat: marker.lat,
                lng: marker.lng,
              }"
            />
            <gmap-marker
              title="New Location"
              v-if="selectedPoi"
              :position="this.center"
            />
            <gmap-marker
              title="New Location"
              v-if="this.center && this.chosenLatitude != null && this.chosenLongitude != null"
              :position="this.center"
            />
            <!-- Circles -->
            <gmap-circle
              :radius="c.radius"
              :center="{
                lat: c.lat,
                lng: c.lng,
              }"
              :fillColor="radiusBar.fillColor"
              :fillOpacity="radiusBar.fillOpacity"
              :strokeColor="radiusBar.strokeColor"
              :strokeOpacity="radiusBar.strokeOpacity"
              :key="c.id"
              v-for="c in pois"
            ></gmap-circle>
  
            <gmap-circle
              :radius="radius"
              :center="center"
              :fillColor="radiusBar.fillColor"
              :fillOpacity="radiusBar.fillOpacity"
              :strokeColor="radiusBar.strokeColor"
              :strokeOpacity="radiusBar.strokeOpacity"
            ></gmap-circle>
            
          </gmap-map>
        </v-card>
        <v-card flat class="detailAdd" tile>
          <div class="pt-2">
            <div class="d-flex justify-center" style="flex-flow: wrap;" v-if="selectedFunction">
              <v-chip 
                style="margin: 0 3px 3px 0;"
                :color="selectedFunction.includes(location) ? 'tertiary' : ''" 
                :outlined="selectedFunction.includes(location) ? false : true"
                v-for="(location, index) in locationTypes" 
                :key="index" 
                small  
                class="chippy"
                @click="functionChange(location)">
                {{ location }}
              </v-chip>
              <!-- <v-select
                    outlined
                    dense
                    hide-selected
                    multiple=""
                    small-chips
                    deletable-chips
                    counter="5"
                    @change="checkRequired('function')"
                    placeholder="Select Function"
                    persistent-placeholder
                    label="Function*"
                    prepend-inner-icon="warehouse"
                    v-model="selectedFunction"
                    :items="locationTypes"
                    :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>  -->
            </div>
          </div>
            <v-row no-gutters>
                <v-col cols="12" sm="4" class="pa-4">
                    <v-text-field
                    placeholder="Enter Location Name"
                    hint="Autocompleted by Google Search"
                    outlined
                    dense
                    class="mb-2"
                    @change="checkRequired()"
                    label="Location Name*"
                    persistent-placeholder
                    v-model="placeName"
                    prepend-inner-icon="edit_note"
                    hide-details
                    />
                    <v-text-field
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    label="Friendly Name"
                    @change="checkRequired()"
                    persistent-placeholder
                    v-model="friendlyName"
                    prepend-inner-icon="edit_note"
                    />
                    <v-autocomplete
                      persistent-placeholder
                      placeholder="Search for an organisation"
                      outlined
                      class="mb-2"
                      dense
                      hide-no-data
                      clearable
                      hide-details
                      return-object
                      @change="checkRequired()"
                      label="Organisation*"
                      v-model="selectedOrganisation"
                      prepend-inner-icon="business"
                      :loading="searchingOrganisations"
                      :search-input.sync="searchOrganisations"
                      :menu-props="{ closeOnContentClick: true }"
                      :items="organisations.data"
                      @input="getFboCodes"
                      item-text="name"
                      item-value="id"
                    > 
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      outlined
                      :key="aliasKey"
                      dense
                      persistent-hint
                      readonly
                      clearable
                      hide-details
                      persistent-placeholder
                      v-model="aliases[0].alias"
                      placeholder="Add an alternative name"
                      label="Alias"
                      prepend-inner-icon="new_label"
                      class="mb-4 activityPoint"
                      :loading="loadingAliases"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top open-delay="250">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              @click="openAlias"
                              v-on="on"
                              style="bottom: 0.7vh"
                            >
                              <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                                >add_circle_outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Add an Alternative Name</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="pa-4">
                <v-row id="locationGeoInfo" no-gutters>
                    <v-col cols="12" md="12" sm="12" >
                      <v-autocomplete
                        persistent-placeholder
                        placeholder="Search for the nearest Town/City"
                        outlined
                        dense
                        hide-no-data
                        class="mb-2"
                        hide-details
                        clearable
                        return-object
                        label="Town/City*"
                        v-model="selectedLocode"
                        @change="checkRequired()"
                        :loading="searchingLocodes"
                        :search-input.sync="searchLocodes"
                        :menu-props="{ closeOnContentClick: true }"
                        :items="locodes.data"
                        item-text="name"
                        item-value="id"
                      >     
                      <template v-slot:selection="data">
                           <span> {{ data.item.name }} - <b>{{ data.item.locode }}</b></span>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }} - <b>{{ item.locode }}</b></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>

                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        persistent-placeholder
                        class="mb-2"
                        label="Address"
                        placeholder="Autocompleted by Google Search"
                        v-model="formattedAddress"
                        readonly
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="pr-1">
                      <v-text-field
                        persistent-placeholder
                        outlined
                        readonly
                        class="mb-2"
                        dense
                        hide-details
                        @change="checkRequired()"
                        label="Country"
                        v-model="selectedCountry.name"
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="pl-1">
                      <v-text-field
                        persistent-placeholder
                        class="mb-2"
                        hide-details
                        outlined
                        @change="checkRequired()"
                        dense
                        label="Province"
                        v-model="province"
                      />
                    </v-col>                                                  
                    <v-col cols="12" md="6" sm="6" class="pr-1" >
                      <v-text-field
                        persistent-placeholder
                        readonly
                        class="mb-2"
                        outlined
                        hide-details
                        dense
                        @change="checkRequired()"
                        label="Latitude*"
                        v-model="chosenLatitude"
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="pl-1">
                      <v-text-field
                        persistent-placeholder
                        outlined
                        hide-details
                        class="mb-2"
                        readonly
                        @change="checkRequired()"
                        dense
                        label="Longitude*"
                        v-model="chosenLongitude"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" class="pa-4">
                  <v-autocomplete
                      persistent-placeholder
                      placeholder="Search Activity Point or Office"
                      outlined
                      class="mb-2"
                      dense
                      hide-no-data
                      clearable
                      hide-details
                      @change="checkRequired()"
                      return-object
                      @click:clear="selectedAP = {id: null,name: null,code: null}"
                      label="Activity-Point"
                      v-model="selectedAP"
                      :search-input.sync="acitvityPointSearch"
                      :loading="searchingActivityPoints"
                      :menu-props="{ closeOnContentClick: true }"
                      prepend-inner-icon="domain_add"
                      
                      :items="moreActivities.data"
                      item-text="code"
                      item-value="id"
                    > 
                      <template v-slot:item="{ item }">
                        <v-list-item dense @click="selectActivityPoint(item)">
                          <v-list-item-avatar>
                          <v-icon left>business</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.name }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.code }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  <v-autocomplete
                      persistent-placeholder
                      placeholder="Search FBO-Code or Organisation"
                      outlined
                      class="mb-2"
                      dense
                      hide-no-data
                      clearable
                      hide-details
                      return-object
                      @click:clear="selectedFbo = {id: null,fboCode: null,fboName: null}"
                      :error-messages="fboCodeUsed ? 'FBO-Code already in use' : ''"
                      label="FBO-Code*"
                      v-model="selectedFbo"
                      :search-input.sync="fboSearchAll"
                      :loading="searchingFbos"
                      :menu-props="{ closeOnContentClick: true }"
                      prepend-inner-icon="tag"
                      :error="fboCodeUsed"
                      :items="moreFboCodes.data"
                      item-text="fboCode"
                      item-value="id"
                    > 
                      <template v-slot:item="{ item }">
                        <v-list-item dense @click="selectFbo(item)">
                          <v-list-item-avatar>
                            <v-icon left>business</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span>{{ item.fboCode }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.fboName }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ item.fboType }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      persistent-placeholder
                      placeholder="Enter a Location Code"
                      outlined
                      dense
                      class="mb-2"
                      clearable
                      hide-details
                      label="Location Code"
                      v-model="locationCode"
                      prepend-inner-icon="tag"
                    />
                    <div style="width: 100%; height: 40px ; display: flex; justify-content: center; align-items: center;">
                      <v-btn
                      :disabled="saveDisabled"
                      color="edit"
                      @click="createPoi"
                      small
                      >
                      Save
                    </v-btn>
                  </div>
                </v-col>

            </v-row>
        </v-card>
        <!-- Country Pop-Out -->
        <transition name="slide-country">
          <v-card class="countrySelectCard" v-if="revealCountryAuto">
            <v-autocomplete
              ref="countrySelect"
              class="countryAuto"
              clearable
              outlined
              hide-details
              dense
              :items="countries"
              item-text="name"
              item-value="id"
              :menu-props="{ closeOnContentClick: true }"
              return-object
              v-model="selectedCountry"
              @change="countryChange"
              @clear="countryClear"
              :loading="loadingCountry"
            >
              <template v-slot:selection="data">
                <v-avatar size="32" v-if="data.item">
                  <v-img
                    v-if="data.item.iso2"
                    contain
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                <span v-if="data.item" class="ml-2">
                  {{ data.item.name }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card>
        </transition>

        <!-- Select POI to Attach to Unmapped one -->
        <v-card v-if="queryLocation != null && container == null"
          style="
            position: absolute; bottom: 1vh; height: 14vh; width: 30vw;
            z-index: 2000; left: 50%; transform: translate(-50%, 0); 
            background-color:var(--v-greyRaised-base) !important;"
        >
        <v-card-text class="pt-2" style="text-align:center">
          <v-card-subtitle class="pa-0" style="font-size: x-large;">Select POI</v-card-subtitle>
          <v-card-subtitle class="px-0 py-1" v-if="!selectedPoi">No POI has been selected</v-card-subtitle>
          <v-card-subtitle class="px-0 pb-1 pt-2" v-if="selectedPoi">Select <b>{{ selectedPoi.name }}</b> as your {{queryLocation.point}}?</v-card-subtitle>
          <div style="display: flex; justify-content: center;">
          <v-checkbox class="pa-0 ma-0"
            v-model="applyToAll"
            hide-details
            dense
          >
            <template v-slot:label>
                <i style="font-size: small;">Apply to all instances of <b>{{queryLocation.name}}</b></i>
            </template>
          </v-checkbox>
        </div>
          <v-btn class="mt-1" color="edit" @click="selectPoi()">Confirm</v-btn>
        </v-card-text>
        </v-card>

        <!-- Select POI to Add to Missing One -->
        <v-card v-if="queryLocation == null && container != null"
          style="
            position: absolute; bottom: 1vh; height: 14vh; width: 30vw;
            z-index: 2000; left: 50%; transform: translate(-50%, 0); 
            background-color:var(--v-greyRaised-base) !important;"
        >
        <v-card-text class="pt-2" style="text-align:center">
          <v-card-subtitle class="pa-0" style="font-size: x-large;">Select POI</v-card-subtitle>
          <v-card-subtitle class="px-0 py-1" v-if="!selectedPoi">No POI has been selected</v-card-subtitle>
          <v-card-subtitle class="px-0 pb-1 pt-2" v-if="selectedPoi">Select <b>{{ selectedPoi.name }}</b> as your {{ container.point }}</v-card-subtitle>
          <div style="display: flex; justify-content: center;">
        </div>
          <v-btn class="mt-1" color="edit" @click="addMissing()">Confirm</v-btn>
        </v-card-text>
        </v-card>

  
        <!-- Location Search -->
        <v-card class="toolbar" flat>
          <v-row>
            <v-btn id="country-filter" icon class="countryAvatar" @click="revealCountry">
              <v-avatar size="38">
                <v-img
                  v-if="selectedCountry.iso2"
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedCountry.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </v-btn>
            <gmap-autocomplete
              key="autocompleteKey"
              class="form-control"
              @place_changed="setPlace"
              :componentRestrictions="countrySelected"
            >
              <template v-slot:default="slotProps" >
                <v-text-field
                  id="country-search"
                  outlined
                  dense
                  clearable
                  label="Location"
                  class="locationInput"
                  prepend-inner-icon="place"
                  @click:clear="clearPlace"
                  v-model="placeName"
                  placeholder="Search for a location"
                  persistent-placeholder
                  hide-details
                  ref="input"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs"
                  @change="viewInfo = true"
                  color="primary"
                >
                <template v-slot:append-outer>
                  <v-tooltip right open-delay="400">
                    <template v-slot:activator="{on}">
                      <v-btn small @click="expand = !expand" icon v-on="on">
                        <v-icon>
                          architecture
                        </v-icon>
                      </v-btn>
                    </template>
                    Lat/Lng Search
                  </v-tooltip>
                </template>
                </v-text-field>
              </template>
            </gmap-autocomplete>
          </v-row>
        </v-card>
        <!-- Google Search Expanded Information Card -->
        <v-expand-transition>
          <v-card v-if="expand" class="expandedInfo" >
            <v-row>
              <v-col cols="12" md="6" sm="6" class="my-0 py-0">
                <v-text-field
                  outlined
                  dense
                  label="Latitude"
                  class="locationData"
                  v-model="chosenLatitude"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6" class="my-0 py-0">
                <v-text-field
                  outlined
                  dense
                  label="Longitude"
                  class="locationData"
                  v-model="chosenLongitude"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
  
        <!-- Alias Card -->
        <v-dialog v-model="aliasCard" max-width="650">
          <v-card height="550" style="overflow-y: hidden">
            <v-toolbar style="height: 10vh" dark>
              <span style="margin: 0 0 -4vh 0; font-size: larger"><p> Add an Alias <br /></p>
               <span style="font-size: small; font-style:italic; opacity: 0.6; line-height: 0.2 "><p>This is because there are multiple names for the same places.<br/></p>
                 <p>ex. Cape Town Container Terminal is also known as the Ben Schoeman Container Terminal</p></span></span>
              <v-spacer></v-spacer>
              <v-btn icon @click="openAlias"
                ><v-icon large color="primary">close</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="12" sm="12" style="display: grid; justify-items: center" >
                  <v-text-field
                    outlined
                    dense
                    label="Alias"
                    persistent-placeholder
                    v-model="aliasToAdd.alias"
                    clearable
                    class="aliasAddBar"
                    placeholder="Add an Alternative Name for this POI"
                    @keyup.enter="addAlias"
                  >
                  </v-text-field>
                  <v-autocomplete
                    style="width: 18vw; margin: -1vh 0 -1.5vh 0;"
                    persistent-placeholder
                    placeholder="What organisation uses this Alias?"
                    outlined
                    dense
                    hide-no-data
                    clearable
                    return-object
                    label="Associated Organisation"
                    v-model="aliasToAdd.aliasOrganisation"
                    prepend-inner-icon="business"
                    :loading="searchingAliasOrganisations"
                    :search-input.sync="searchAliasOrganisations"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="aliasOrganisations.data"
                    item-text="name"
                    item-value="id"
                  > 
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-btn @click="addAlias" :disabled="aliasDisabled" color="success">
                    Accept
                  </v-btn>
                </v-col>
              </v-row>
              <v-card flat style="overflow-y: auto; overflow-x: hidden">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-list height="300" v-if="aliases[0].alias != null">
                      <v-list-item
                        two-line
                        outlined
                        dense
                        v-for="item in aliases"
                        :key="item.alias"
                      >
                        <v-list-item-avatar>
                          <v-icon left>new_label</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.alias }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.aliasType }}
                          </v-list-item-subtitle>
  
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="removeAlias(item)">
                              <v-icon color="error">delete</v-icon>
                            </v-btn>
                          </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
  
  
      </v-col>
    </v-row>
  </template>
  
  <!-- EMITS 
  close = closes dialog...
  newPoi = returns the created poi
  -->

  <script>
  import { getGoogleMapsAPI } from "gmap-vue";
  
  
  export default {
    props: [
    'queryLocation',
    'container',
    'selectedLocationCode'
    ],
    components: {

    },
    data: () => ({
      countries: [],
      places: [],
      fboCodes: [],
      dontView: true,
      aliases: [
        {
          alias: null,
          aliasOrganisation: null
        }
      ],
      moreFboCodes: [],
      moreActivities: [],
      selectedOrganisation: null,
      selectedLocode: null,
      polygons: [],
      polygonsList: [],
      morePois: [],
      pois: [],
      markersInBounds: [],
      poiList: [],
      selectedMarkers: [],
      selectedFbo: {},
      selectedAP: {},
      chosenPoi:[],
      selectedPoi: null,
      selectedFunction: [],
      existingAliases: [],
      aliasToAdd: {
        alias: null,
        aliasOrganisation: null,
      },
      deletedAliases: [],
      aliasType: null,
      functionLimit: 3,
      applyToAll: false,
      place: null,
      
      zoomLevel: 10,
      radius: 0,
      placeholderKey: 0,
      radioKey: 0,
      mapKey: 0,
      routesLocationKey: 0,
      poiKey: 0,
      loadingBounds: 0,
      aliasKey: 0,
      poiLimit: 20,
      fboList: 0,
      actPointList: 0,
      autocompleteKey: 0,
      customName: "",
      address: "",
      formattedAddress: "",
      province: "",
      placeName: null,
      friendlyName: null,
      country: "",
      coordinates: "",
      chosenLatitude: null,
      chosenLongitude: null,
      postalCode: "",
      radiusTool: false,
      fboCodeUsed: false,
      drag: false,
      deleteCard: false,
      editingPoi: false,
      saveDisabled: true,
      countryCollision: false,
      filtersActive: false,
      filterPoiCard: false,
      fboSearch: null,
      searchingOrganisations: false,
      searchingAliasOrganisations: false,
      searchingLocodes: false,
      aliasDisabled: true,
      viewMarkers: true,
      functionLoading: false,
      updateLoading: false,
      updateDisabled: false,
      subdivisionLoading: false,
      
      loadingCountry: false,
      loadingFboCodes: false,
      loadingAliases: false,
      loadingAps: false,
      saveLoading: false,
      loadingPoiList: true,
      searchingFbos: false,
      searchingPois: false,
      searchingActivityPoints: false,
      searchOrganisations: null,
      searchAliasOrganisations: null,
      searchLocodes: null,
      searchPois: null,
      fboSearchAll: null,
      acitvityPointSearch: null,
      functionFilter: null,
      subdivisionFilter: null,
      zoom: 11,
      revealCountryAuto: false,
      fboCard: false,
      aliasCard: false,
      activityPointCard: false,
      organisationTimeout: null,
      aliasOrgTimeout: null,
      locodeTimeout: null,
      poiSearchTimeout: null,
      fboSearchTimeout: null,
      locationCode: null,
      activitySearchTimeout: null,
      viewInfo: false,
      viewRoutes: false,
      viewSavedRoutes: false,
      viewAreas: false,
      viewSettings: false,
      expand: false,
      customMarker: true,
      mapCursor: "pointer",
      appendIcon: "expand_more",
      countrySelected: { country: "za" },
      limitOptions: [10, 20, 50, "All"],
      currentBounds: {
        north: null,
        south: null,
        east: null,
        west: null,
      }, 
      radiusBar: {
        fillColor: "white",
        fillOpacity: 0.5,
        strokeColor: "red",
        strokeOpacity: 0.8,
      },
      center: {
        lat: -33.9321,
        lng: 18.8602,
       
      },
      bounds: {
        north: -17.31542356200097,
        south: -33.48808586,
        east: 43.30981,
        west: 10.62930204,
      },
      organisations: {
        total: 0,
        data: [],
      },
      aliasOrganisations: {
        total: 0,
        data: [],
      },
      locodes: {
        total: 0,
        data: [],
      },
      selectedCountry: {
        continent: "Africa",
        id: 343,
        name: "South Africa",
        iso2: "ZA",
      },
      params: {
        search: "",
        limit: 'All',
        filter: {
          functionality: null,
          subdivision: null
        }
      },
      locationTypes: [
        "Farm",
        "Pack-House (On-Farm)",
        "Pack-House (Off-Farm)",
        "Dry Storage",
        "Commercial Cold Store",
        "Warehouse",
        "Distribution Center",
        "Drying Facility",
        "Weigh-Bridge",
        "Toll-Gate",
        "Truck-Depot",
        "Port",
        "Port Terminal",
        "Airport Terminal",
        "Exporter",
        "Head-Office",
        "Regional Office",
        "Container Depot",
        "Regulatory Body",
        "Plug-in Depot",
        "Gassing Point",
        "Other",
      ],
      infoPosition: null,
      infoWindow: {
        template: "",
      },
      infoOpened: false,
      currentMarker: null,
    }),
  
    computed: {
      google: getGoogleMapsAPI,
  
      displayMarkers() {
      return this.poiList.filter((x) => this.selectedMarkers.includes(x.id));
    },

      filteredSelectedFunctions() {
        return this.selectedFunction.slice(0, this.functionLimit);
      },
    },

    async created() {
      this.getCountries();
      this.getMarkers()
    },

    // -33.9051290695735
    // 18.77968933524098
  
    watch: {
      selectedLocationCode:{
        immediate: true, 
        handler(val){
          console.log("selected location code", val)
          this.locationCode = val ?? null
        console.log(this.locationCode)
        }
      },
    'chosenLatitude': function (val) {
        val = parseFloat(val);
        if(!isNaN(val) && this.chosenLongitude !== null) {
            if(val >= -90 && val <= 90) {
                this.center = {lat: val, lng: parseFloat(this.chosenLongitude)};
            } else {
                console.error("Invalid latitude value");
            }
        }
    },
    'chosenLongitude': function (val) {
        val = parseFloat(val);
        if(!isNaN(val) && this.chosenLatitude !== null) {
            if(val >= -180 && val <= 180) {
                this.center = {lat: parseFloat(this.chosenLatitude), lng: val};
            } else {
                console.error("Invalid longitude value");
            }
        }
    },

      'queryLocation': function (val) {
        if (val && val.lat && val.lng) {
          this.center = {lat: val.lat, lng: val.lng}
        }
      },

      async searchOrganisations(val) {
        if (this.searchingOrganisations) {
          clearTimeout(this.organisationTimeout);
        }
        if (val && val.length > 2) {
          this.organisationTimeout = setTimeout(async () => {
            this.searchingOrganisations = true;
            this.organisations = await this.$API.getOrganisationsPOI({
              params: {
                search: val,
                limit: 15,
              },
            });
            this.searchingOrganisations = false;
          }, 150);
          this.searchingOrganisations = false;
        } else {
          clearTimeout(this.organisationTimeout);
          this.searchingOrganisations = false;
        }
      },
  
      async searchAliasOrganisations(val) {
        if (this.searchingAliasOrganisations) {
          clearTimeout(this.aliasOrgTimeout);
        }
        if (val && val.length > 2) {
          this.aliasOrgTimeout = setTimeout(async () => {
            this.searchingAliasOrganisations = true;
            this.aliasOrganisations = await this.$API.getOrganisationsPOI({
              params: {
                search: val,
                limit: 15,
              },
            });
            this.searchingAliasOrganisations = false;
          }, 150);
          this.searchingAliasOrganisations = false;
        } else {
          clearTimeout(this.aliasOrgTimeout);
          this.searchingAliasOrganisations = false;
        }
      },
  
      async searchLocodes(val) {
        if (this.searchingLocodes) {
          clearTimeout(this.locodeTimeout);
        }
        if (val && val.length > 2) {
          this.locodeTimeout = setTimeout(async () => {
            this.searchingLocodes = true;
            this.locodes = await this.$API.searchLocodes({
              params: {
                filter: this.selectedCountry.iso2,
                search: val,
                limit: 15,
              },
            });
            this.searchingLocodes = false;
          }, 150);
          this.searchingLocodes = false;
        } else {
          clearTimeout(this.locodeTimeout);
          this.searchingLocodes = false;
        }
      },
  
      'aliasToAdd.alias': {
        handler: function (val) {
          if (val && val.length >= 4) {
            this.aliasDisabled = false;
          } else {
            this.aliasDisabled = true;
          }
        }
      },
  
      async fboSearchAll(val) {
        if (this.searchingFbos) {
          clearTimeout(this.fboSearchTimeout);
        }
        if (val && val.length > 2) {
          this.fboSearchTimeout = setTimeout(async () => {
            this.searchingFbos = true;
            this.moreFboCodes = await this.$API.getFboCodes({
              params: {
                search: val,
                limit: 15,
              },
            });
            this.searchingFbos = false;
            this.fboSearchTimeout = null;
            this.fboList++;
          }, 150);
        } else {
          clearTimeout(this.fboSearchTimeout);
        }
      },
  
      async acitvityPointSearch(val) {
        if (this.searchingActivityPoints) {
          clearTimeout(this.activitySearchTimeout);
        }
        if (val && val.length > 2) {
          this.activitySearchTimeout = setTimeout(async () => {
            this.searchingActivityPoints = true;
            this.moreActivities = await this.$API.getActivityPoints({
              params: {
                search: val,
                limit: 20,
              },
            });
            this.searchingActivityPoints = false;
            this.activitySearchTimeout = null;
            this.actPointList++;
          }, 150);
        } else {
          clearTimeout(this.activitySearchTimeout);
        }
      },
    },
  
    methods: {
      // POI's //////////////////////////// POI's ///////////////////////////////////////////////// POI's //////////////////////////////////////// POI's /////////////////////////// POI's //////////////

      selectPoi() {
        let obj = 
        { 
          poi: {
                ...this.chosenPoi,
              },
          queryLocation: this.queryLocation,
          applyToAll: this.applyToAll
      }
      this.$emit('selectedPoi', obj)
    },

    addMissing() {
      let obj = 
      {
        container: this.container,
        poi:{...this.chosenPoi},
      }
      this.queryLocation = null
      this.applyToAll = false
      this.container = null
      this.clearPoi()
      this.clearPlace()
      this.$emit('addMissing', obj)
    },

      enforceSelectionLimit() {
        if (this.selectedFunction.length > this.functionLimit) {
          this.selectedFunction = this.selectedFunction.slice(0, this.functionLimit);
        }
      },
  
      checkRequired(val=null) {
        if(val == 'function') {
          this.enforceSelectionLimit();
        }
        if (
          // this.fboCodeUsed == false &&
          this.placeName != null &&
          this.selectedFunction != null &&
          // this.selectedOrganisation != null  &&
          this.chosenLatitude != null &&
          this.chosenLongitude != null
        ) {
          this.saveDisabled = false;
        } else {
          this.saveDisabled = true;
        }
      },
  
      
      clearPoi() {
        this.selectedPoi = null 
        this.chosenPoi = null
        this.radioKey++
        this.editingPoi= false;
        this.placeName = null;
        this.friendlyName = null
        this.formattedAddress = null;
        this.postalCode = null;
        this.selectedFunction = [];
        this.chosenLatitude = null;
        this.chosenLongitude = null;
        this.radius = 0;
        this.province = null;
        this.aliases = [{
          alias: null,
          aliasOrganisation: null
        }];
        this.selectedOrganisation = {
          id: null,
          name: null
        }
        this.organisations = {
          total: 0,
          data: []
        }
        this.selectedFbo = {
          id: null,
          fboCode: null,
          fboName: null
        }
        
        this.selectedAP = {
          id: null,
          name: null,
          code: null
        }
  
        this.selectedLocode = {
          id: null,
          locode: null,
          name: null
        }
        this.locodes = {
          total: 0,
          data: []
        }
      },

      functionChange(location) {
        let find = this.selectedFunction.find(x => x == location)
        if(find) {
          this.selectedFunction.splice(this.selectedFunction.indexOf(location), 1)
        } else {
          this.selectedFunction.push(location)
        }
        this.checkRequired()
      },

      async getMarkers() {
        this.loadingPoiList = true;
        this.pois = await this.$API.getPois({
          params: this.params
        });

        this.loadingPoiList = false;
        this.poiList = this.pois ?? [];
    },
  
      async checkFboCode(item) {
        if (item != null) {
          let validation = await this.$API.checkFboCode(item.fboCode);
          if(validation.status == false) {
            this.fboCodeUsed = false;
          } else if(validation.status == true) {
            this.fboCodeUsed = true;
          }
        }
        else this.fboCodeUsed = false;
      },
  
      clearFboSelected() {
        this.fboCodeUsed = false;
        this.selectedFbo = {
          id: null,
          fboCode: null,
          fboName: null
        }
      },
      viewMarker(poi) {
      console.log(poi)
      this.chosenPoi = poi;
      this.editingPoi= true;
      this.center = {
        lat: poi.lat,
        lng: poi.lng,
      };
      this.placeName = poi.name
      this.friendlyName = poi.friendlyName
      this.formattedAddress = poi.address
      this.postalCode = poi.postalCode
      this.chosenLatitude = poi.lat
      this.chosenLongitude = poi.lng
      this.radius = poi.radius
      this.province = poi.subdivision
      if(poi.organisation != null) {
      this.selectedOrganisation = {
        id: poi.organisationId,
        name: poi.organisation.name
      }

      this.organisations = {
        total: 1,
        data: [this.selectedOrganisation]
      }
      } else {
        this.selectedOrganisation = {
          id: null,
          name: null
        }
        this.organisations = {
          total: 0,
          data: []
        }
      }
      if(poi.organisationFbo != null) {
        this.selectedFbo = {
        id: poi.organisationFboId,
        fboCode: poi.fboCode,
        fboName: poi.organisationFbo.fboName
      }
      } else {
        this.selectedFbo = {
        id: null,
        fboCode: null,
        fboName: null
      }
      }
      if(poi.activityPoint != null) {
        this.selectedAP = {
        id: poi.activityPoint.id,
        name: poi.activityPoint.name,
        code: poi.activityPoint.code
      }
      } else {
        this.selectedAP = {
        id: null,
        name: null,
        code: null
      }
      }
      if(poi.unLocode != null){
        this.selectedLocode = {
        id: poi.unLocode.id,
        locode: poi.unLocode.locode,
        name: poi.unLocode.name
      }
      this.locodes = {
        total: 1,
        data: [this.selectedLocode]
      }
      } else {
        this.selectedLocode = {
        id: null,
        locode: null,
        name: null
      }
      this.locodes = {
        total: 0,
        data: []
      }
      }
      if(poi.poiAliases != null && poi.poiAliases.length != 0) {
        this.existingAliases = poi.poiAliases
        console.log(this.existingAliases)
        this.aliases = poi.poiAliases
        console.log(this.aliases)
      } else {
        this.aliases = [{
          alias: null,
          aliasOrganisation: null
        }]
      }
      if(poi.poiFunctions != null) {
        if(poi.poiFunctions.length > 0){
            this.selectedFunction =[poi.poiFunctions[0].functionality]
          }
          if(poi.poiFunctions.length > 1){
            this.selectedFunction =[poi.poiFunctions[0].functionality, poi.poiFunctions[1].functionality]
          }
          if(poi.poiFunctions.length > 2){
            this.selectedFunction =[poi.poiFunctions[0].functionality, poi.poiFunctions[1].functionality, poi.poiFunctions[2].functionality]
          }
      }
    },
  
      async updatePoi() {
        this.updateLoading = true;
        let data = {
          id: this.chosenPoi.id,
          name: this.placeName,
          friendlyName: this.friendlyName,
          address: this.formattedAddress,
          city:  this.selectedLocode ? this.selectedLocode.name : this.vicinity,
          country: this.country,
          subdivision: this.province,
          postalCode: this.postalCode,
          lat: this.chosenLatitude,
          lng: this.chosenLongitude,
          radius: this.radius,
          organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
          organisationFboId: this.selectedFbo.id,
          fboCode: this.selectedFbo.fboCode,
          unLocodeId: this.selectedLocode ? this.selectedLocode.id : null,
          locationCode: this.locationCode ? this.locationCode : null,
        };
        let poi = await this.$API.updatePoi(data);                                                                                //Update the POI
        if (this.selectedAP.name && poi) {
          let activityPoint = {
          id: this.selectedAP.id,
          organisationId: this.selectedOrganisation.id,
          poiId: poi.id,
          };
          await this.$API.updateActivityPoint(activityPoint);
        }
        if(this.chosenPoi.poiFunctions.length != 0 && this.selectedFunction != null) {                                            //If the POI has functions and the user has selected functions
          if(this.chosenPoi.poiFunctions.length == this.selectedFunction.length ) {                                               //If the length of the POI's functions are equal to that of 
            for(let i = 0; i < this.selectedFunction.length ; i++) {                                                              //the length of the selected functions, then replace those functions
             let poiFunction = {
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
            }
          }
          else if(this.chosenPoi.poiFunctions.length > this.selectedFunction.length) {                                            
            for(let i = 0; i < this.selectedFunction.length; i++) {                                                               //If the length of the POI's functions are greater than that of
             let poiFunction = {                                                                                                  //the selected functions, then replace them and delete the rest
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
            }
            for(let i = this.selectedFunction.length; i < this.chosenPoi.poiFunctions.length; i++) {
              await this.$API.deletePoiFunction(this.chosenPoi.poiFunctions[i].id);
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.splice(i, 1);
            }
          }
          else if(this.chosenPoi.poiFunctions.length < this.selectedFunction.length) {                                            //If the length of the POI's functions are less than that of
            for(let i = 0; i < this.chosenPoi.poiFunctions.length; i++) {                                                         //the selected functions, then replace those and create the rest
             let poiFunction = {
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions[i] = result;
            }
            for(let i = this.chosenPoi.poiFunctions.length; i < this.selectedFunction.length; i++) {
              let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: this.selectedFunction[i]});
              this.$emit('poiCreated', result)
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.push(result);
            }
          }
        }
          else {                                                                                                                //If the POI has no functions and the user has selected functions
            if(this.selectedFunction != null) {                                                                                 //then create those functions
              this.selectedFunction.forEach(async x =>  {
              let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: x})
              x = result;
              this.pois.find(poi => poi.id == this.chosenPoi.id).poiFunctions.push(result);
          })
          }
        }
        if(this.aliases[0].alias != null && this.aliases[0].alias != "") {                                                       //If the user has entered aliases, then create them
          
          this.aliases.forEach(async x => {
            if(x.new) {
              let result = await this.$API.createPoiAlias({poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
              x.id = result.id;
            } 
            else {
              let result = await this.$API.updatePoiAlias({id: x.id, poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
              x = result;
            }
            });
          this.deletedAliases.forEach(async x => {
            if(Object.hasOwn(x, "id")){
                await this.$API.deletePoiAlias(x.id);
              } else {
                return;
              }
          });
          this.deletedAliases = [];
          let find = this.pois.find(poi => poi.id == this.chosenPoi.id)
          find.poiAliases = this.aliases;
          
        }
        let find = this.poiList.find(poi => poi.id == this.chosenPoi.id)
        console.log(find)
          find = {...find, ...data}
        console.log(find)
        this.updateLoading = false;
        this.clearPoi();
        this.$message({
          message: "Poi updated successfully",
          type: "success",
        });
      },
  
      async createPoi() {
        this.saveLoading = true;
        let data = {
          name: this.placeName,
          address: this.formattedAddress,
          friendlyName: this.friendlyName,
          city: this.selectedLocode ? this.selectedLocode.name : this.vicinity,
          country: this.country,
          subdivision: this.province,
          postalCode: this.postalCode,
          lat: this.chosenLatitude,
          lng: this.chosenLongitude,
          radius: this.radius,
          organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
          organisationFboId: this.selectedFbo.id,
          fboCode: this.selectedFbo.fboCode,
          unLocodeId: this.selectedLocode ? this.selectedLocode.id : null,
          locationCode: this.locationCode ? this.locationCode : null,
        };
  
        let poi = await this.$API.createPoi(data);
        this.$emit('poiCreated', poi)
        if(this.selectedFunction != null && poi) {
          this.selectedFunction.forEach(async x =>  {
            await this.$API.createPoiFunction({poiId: poi.id, functionality: x})
          })
        }
  
        if(this.aliases[0].alias != null) {
          this.aliases.forEach(async x => {
            let result = await this.$API.createPoiAlias({poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
          })
        }
  
        if (this.selectedAP.name && poi) {
          let activityPoint = {
          id: this.selectedAP.id,
          organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
          poiId: poi.id,
          };
          await this.$API.updateActivityPoint(activityPoint);
        }
        let newPoi = {
          ...poi,
          organisation: this.selectedOrganisation,
          organisationFbo: this.selectedFbo,
          activityPoint: this.selectedAP,
          unLocode: this.selectedLocode,
          poiFunctions: this.selectedFunction ? this.selectedFunction.map(x => ({poiId: poi.id, functionality: x})) : [],
          poiAliases: this.aliases,
        }
        this.poiList.push(newPoi);
        this.pois.push(newPoi);
        this.$emit('newPoi', newPoi)
        this.clearPoi();
        this.saveLoading = false;
      },
  
      addAlias() {
        console.log(this.aliasToAdd)
        if(this.aliases[0].alias != null) {
        if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
          if(this.aliasToAdd.aliasOrganisation != null) {
            this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true});
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            };
          } else {
            this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true});
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          }
  
        } else this.$message({
          message: "Alias cannot be empty",
          type: "error",
        });
      } else {
        if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
          if(this.aliasToAdd.aliasOrganisation != null) {
            this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true}
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          } else {
            this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true}
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          }
        } else this.$message({
          message: "Alias cannot be empty",
          type: "error",
        });
      }
      },
  
      async removeAlias(alias) {
        this.deletedAliases.push(alias);
        console.log(this.deletedAliases)
        
        if(this.aliases.length > 1) {
         let index = this.aliases.findIndex(x => x.alias === alias.alias);
            if (index !== -1) {
              this.aliases.splice(index, 1);
            }
        } else {
          this.aliases = [{alias: null, aliasType: null, organisationId: null}]
        }
      },
  
      selectFbo(item) {
        this.selectedFbo = item;
        this.checkFboCode(item);
        this.fboCard = false;
      },
  
      selectActivityPoint(item) {
        this.selectedAP = item;
        this.activityPointCard = false;
      },
  
      clearPlace() {
        this.place = null;
        (this.customMarker = true), (this.formattedAddress = "");
        this.placeName = "";
        this.friendlyName = ""
        this.country = " ";
        this.address = " ";
        this.coordinates = " ";
        this.chosenLatitude = " ";
        this.chosenLongitude = " ";
        this.postalCode = " ";
      },
  
      addFbo() {
        if (this.fboCard) {
          this.fboCard = false;
        } else this.fboCard = true;
      },
  
      openAlias() {
        if (this.aliasCard) {
          this.aliasCard = false;
        } else this.aliasCard = true;
      },
  
      addActivityPoint() {
        if (this.activityPointCard) {
          this.activityPointCard = false;
        } else this.activityPointCard = true;
      },
  
      filterPois() {
        if (this.filterPoiCard === true) {
          this.filterPoiCard = false;
        } else {
          this.filterPoiCard = true;
        }
      },
  
      async getFboCodes(val) {
        this.fboCodes = await this.$API.getOrgFboCodes(val.id);
      },
  
      // MAP //////////////////////////// MAP ///////////////////////////////////////////////// MAP //////////////////////////////////////// MAP /////////////////////////// MAP //////////////
  
      getZoom(zoom) {
        this.zoomLevel = zoom;
        console.log(this.zoomLevel)
      },
  
      async getBounds(bounds) {
        clearTimeout(this.boundsTimer);
        this.boundsTimer = setTimeout(() => {
          let values = Object.values(bounds);
          this.currentBounds.north = values[0].hi;
          this.currentBounds.south = values[0].lo;
          this.currentBounds.east = values[1].hi;
          this.currentBounds.west = values[1].lo;
        }, 2000);
        
        if(this.zoomLevel > 11 && !this.loadingBounds) {
          this.loadingBounds = true;
          let markers = await this.$API.getMarkersInBounds(this.currentBounds);
          if(markers) {
            this.markersInBounds = markers;
            this.loadingBounds = false;
          }
        }
      },
  
      updateCoordinates(data) {
        this.chosenLatitude = data.latLng.lat();
          this.chosenLatitude = this.chosenLatitude.toFixed(7);
          this.chosenLongitude = data.latLng.lng();
          this.chosenLongitude = this.chosenLongitude.toFixed(7);
      },
  
      // MARKERS //////////////////////////// MARKERS ///////////////////////////////////////////////// MARKERS //////////////////////////////////////// MARKERS /////////////////////////// MARKERS //////////////
      setPlace(place) {
        console.log(place)
        this.place = place;
        this.formattedAddress = place.formatted_address;
        this.placeName = place.name;
        this.country = place.formatted_address.split(",").pop();
        this.center = place.geometry.location;
        this.address = this.place.vicinity;
        this.coordinates = this.place.geometry.location;
        this.customMarker = false;
        this.chosenLatitude = this.coordinates
          .toString()
          .replace(/[()]/g, "")
          .split(",")[0];
        this.chosenLongitude = this.coordinates
          .toString()
          .replace(/[()]/g, "")
          .split(",")[1];
        for (let i = 0; i < this.place.address_components.length; i++) {
          if (this.place.address_components[i].types[0] == "postal_code") {
            this.postalCode = this.place.address_components[i].long_name;
          }
        }
        for (let i = 0; i < this.place.address_components.length; i++) {
          if (
            this.place.address_components[i].types[0] ==
            "administrative_area_level_1"
          ) {
            this.province = this.place.address_components[i].long_name;
          }
        }
      },
      addMarker(data) {
        if (this.viewRoutes) {
          console.log('It got to the first section')
          const newIndex = this.routeWaypoints.findIndex(
            (point) => point.lat === null && point.lng === null
          );
        console.log(newIndex, this.routeWaypoints,  'this is the index it got')
          if (newIndex !== -1) {
            this.routeWaypoints[newIndex].lat = data.latLng.lat()
            this.routeWaypoints[newIndex].lng = data.latLng.lng()
            this.routeWaypoints[newIndex].name = "Custom Marker" + " " + (newIndex + 1);
            this.routesLocationKey++;
            console.log('it assigned it so if it did that then it needs an update key', this.routeWaypoints)
            this.getRoute();
            this.routesLocationKey++;
          }
        } else if (this.customMarker) {
          this.center = {
            lat: data.latLng.lat(),
            lng: data.latLng.lng(),
          };
          console.log(data);
          this.chosenLatitude = data.latLng.lat().toFixed(7);
          this.chosenLongitude = data.latLng.lng().toFixed(7);
        }
  },
  
      // COUNTRIES //////////////////////////// COUNTRIES ///////////////////////////////////////////////// COUNTRIES //////////////////////////////////////// COUNTRIES /////////////////////////// COUNTRIES //////////////
  
      expandedInfo() {
        if (this.appendIcon == "expand_more") {
          this.appendIcon = "expand_less";
          this.expand = true;
        } else {
          this.appendIcon = "expand_more";
          this.expand = false;
        }
      },
  
      async getCountries() {
        this.countries = await this.$API.searchCountriesBasic({});
      },
  
      countryChange() {
        this.$refs.countrySelect.blur();
        this.revealCountryAuto = false;
        this.countrySelected = {
          country: `${this.selectedCountry.iso2.toLowerCase()}`,
        };
        this.autocompleteKey++;
        if (this.selectedCountry && !this.revealCountryAuto) {
          this.countryCollision = false;
        }
      },
  
      countryClear() {
        this.countryCollision = true;
      },
  
      revealCountry() {
        if (this.revealCountryAuto) {
          this.revealCountryAuto = false;
          this.countryCollision = false;
        } else if (this.countryCollision && !this.revealCountryAuto) {
          this.countryCollision = false;
        } else {
          this.revealCountryAuto = true;
          this.filterPoiCard = false;
          this.countryCollision = true;
        }
      },
  
      radiusCard() {
        if (this.radiusTool) {
          this.radiusTool = false;
        } else this.radiusTool = true;
      },
  
      // OTHER //////////////////////////// OTHER ///////////////////////////////////////////////// OTHER //////////////////////////////////////// OTHER /////////////////////////// OTHER //////////////
  
    },
  };
  
  
  </script>
  
  <style scoped>

  .v-toolbar__content {
    padding: 0 !important;
  }
  /* MapView */
  .mapLocal {
    height: 60%;
    width: 100%;
  }
  .detailAdd {
    height: 40%;
    width: 100%;
    overflow-y: scroll;
  }
  .markerWaypoints {
    color: #ffffff !important;
  }
  
  /* .mapLocal ::v-deep .gm-style-cc {
    right: 30vw !important;
  } */
  
  .mapLocal ::v-deep .vue-map {
      border-radius: 0px 0px 0px 0px;
  }
  
  /* .mapLocal ::v-deep .gm-style div[style*="z-index: 1000000"] {
    left: 20px !important;
    bottom: 10px !important;
    scale: 1.5 !important;
  } */
  
  .transition {
    transition: all 3s;
  }
  
  .infoFields {
    padding-left: 2vw;
    padding-top: 1vh;
  }
  
  .infoTitleText {
    font-size: 2vh;
  }


  
  
  .closeNav {
    position: absolute;
    top: 1vh;
    right: 0.5vw;
  }
  
  
  .dataSearch {
    width: 15vw;
    margin-top: 1vh;
    margin-bottom: -1vh;
  }
  
  .aliasAddBar {
    width: 18vw;
  }
  
  .mapTypeBtn ::v-deep .v-btn__content {
    justify-content:start;
  }

  .limitSelectorNav ::v-deep .v-input__slot {
    background-color: transparent !important;
  }
  
  .activityPoint {
    width: 19vw;
  }
  .latLng {
    width: 7vw;
  }
  
  .infoSave {
    position: absolute;
    bottom: 1.5vh;
    right: 1vw;
  }
  
  .listToolbar {
    height: 1.5vh;
    contain: content;
    scroll-snap-align: start;
    background-color: var(--v-greyRaised-base) !important
  }

  .chippy.v-chip {
    padding: 0 6px !important;
  }
  .toolbar {
    position: absolute;
    border-radius: 16px;
    z-index: 15 !important;
    height: 5.2vh;
    width: 30vw;
    top: 0.3vh;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    padding-top: 0.2vh;
  }
  
  .countryAvatar {
    width: 1.5vw;
    margin: 1.8vh 0 0 1.5vw;
  }
  
  .countrySelectCard {
    position: absolute;
    border-radius: 16px;
    height: 6vh;
    width: 16vw;
    top: 0.3vh;
    left: 1%;
    z-index: 1;
  }
  
  .countryAuto {
    width: 13.5vw;
    margin: 0.7vh 0 1vh 0.5vw;
  }
  
  .countryList {
    border-bottom-left-radius: 16px;
  }
  
  .filterPoi {
    position: absolute;
    border-radius: 16px;
    height: 20vh;
    width: 13vw;
    top: 11vh;
    left: 17.8vw;
    z-index: 1;
  }
  
  .filterOff {
    position: absolute;
    top: 0.8vh;
    right: 0.8vw;
  }
  
  .locationInput {
    margin: 1.5vh 0 0 0.8vw;
    border-radius: 16px;
    line-height: 4vh;
    width: 26vw;
    z-index: 2000 !important;
  }
  
  .locationInput ::v-deep .v-input__append-outer {
    margin: 6px -5px 0 5px;
  }
  .loadingPoi {
    position: absolute;
    top: 20vh;
    left: 6.7vw;
  }
  
  .locationData {
    border-radius: 10px;
    font-size: small;
  }
  
  .locationAddress {
    border-radius: 10px !important;
    font-size: small;
  }

  
  .expandedInfo {
    position: absolute;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0.5vw 0 0.5vw;
    height: 8.5vh;
    width: 26vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    top: 3vh;
  }

  </style>
  